.container {
  display: flex;
  width: 100%;
  max-width: var(--content-width);

  @media screen and (max-width: 767px) {
    flex-direction: column;
  }

  @media screen and (min-width: 768px) {
    margin: 40px auto 65px;
  }

  @media screen and (min-width: 768px) and (max-width: 1279px) {
    padding: 0 15px;
  }

  @media screen and (min-width: 1280px) and (max-width: 1919px) {
    padding: 0 105px;
  }
}

.image {
  max-width: 100%;
  mix-blend-mode: darken;

  &Container {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--gray-light);

    @media screen and (max-width: 767px) {
      min-height: 40vh;
    }

    @media screen and (min-width: 768px) and (max-width: 1279px) {
      width: calc(50% - 20px);
      flex-shrink: 0;
      margin-right: 40px;
    }

    @media screen and (min-width: 1280px) {
      margin-right: 55px;
      width: 50%;
      flex-shrink: 0;
    }
  }
}

.info {
  display: flex;
  flex-direction: column;
  min-width: 380px;

  @media screen and (max-width: 767px) {
    padding: 30px 15px 40px;
    border-bottom: 1px solid var(--gray-text);
  }

  @media screen and (min-width: 1920px) {
    margin: 30px 0;
  }

  &Header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
  }
}

.expires {
  display: inline-flex;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 0.95rem;
  color: var(--black);

  @media screen and (max-width: 767px) {
    font-weight: 700;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 15px 15px 10px;
    background-color: var(--gray-light);
    border-bottom: 1px solid var(--gray-text);
  }

  @media screen and (min-width: 768px) {
    flex: 2;
    margin-right: 20px;
  }
}

.print {
  flex: 1;
  display: flex;
  align-items: center;
  border: none;
  outline: none;
  background-color: transparent;

  &Text {
    display: inline-flex;
    margin-left: 5px;
    color: var(--primary);
    font-size: 14px;
    font-weight: 500;
    line-height: 13px;
  }
}

.mainInfoContainer {
  display: flex;
  align-items: baseline;
  justify-content: space-between;

  @media screen and (max-width: 1279px) {
    margin-bottom: 15px;
  }

  @media screen and (min-width: 1280px) and (max-width: 1919px) {
    margin-bottom: 20px;
  }

  @media screen and (min-width: 1920px) {
    margin-bottom: 15px;
  }
}

.title {
  font-weight: 700;
  color: var(--black-dark);

  @media screen and (min-width: 1280px) {
    font-size: 2rem;
  }

  @media screen and (max-width: 1279px) {
    margin-bottom: 5px;
    font-size: 1.5rem;
    line-height: 1.5rem;
  }

  &Container {
    display: flex;
    flex-direction: column;
    margin-right: 20px;

    @media screen and (min-width: 768px) {
      flex: 2;
    }
  }
}

.subtitle {
  font-weight: 700;
  color: var(--black-dark);

  @media screen and (max-width: 1279px) {
    font-size: 1.5rem;
    line-height: 1.5rem;
  }

  @media screen and (min-width: 1280px) and (max-width: 1919px) {
    font-size: 2.25rem;
  }

  @media screen and (min-width: 1920px) {
    font-size: 2.5rem;
  }
}

.discount {
  &Container {
    display: flex;
    flex-direction: column;

    @media screen and (min-width: 768px) {
      flex: 1;
    }
  }

  &Value {
    font-weight: 700;
    text-transform: capitalize;
    color: var(--primary);

    @media screen and (max-width: 1279px) {
      margin-bottom: 5px;
      font-size: 1.5rem;
      line-height: 1.5rem;
    }

    @media screen and (min-width: 1280px) and (max-width: 1919px) {
      font-size: 2.25rem;
    }

    @media screen and (min-width: 1920px) {
      font-size: 2.5rem;
    }
  }

  &Description {
    color: var(--primary);
    font-weight: 500;

    @media screen and (min-width: 1280px) {
      font-size: 2rem;
    }

    @media screen and (max-width: 1279px) {
      margin-bottom: 5px;
      font-size: 1rem;
    }
  }

  &Value,
  &Description {
    white-space: nowrap;
  }
}

.description {
  font-size: 1rem;
  line-height: 1.5rem;
  color: var(--black);

  @media screen and (min-width: 1920px) {
    margin-bottom: 40px;
  }

  @media screen and (max-width: 1919px) {
    margin-bottom: 25px;
  }
}

.actions {
  display: flex;

  @media screen and (max-width: 767px) {
    flex-direction: column-reverse;
    margin-bottom: 15px;
  }

  @media screen and (min-width: 768px) {
    margin-bottom: 35px;
    align-items: center;
  }

  .button {
    @media screen and (min-width: 768px) {
      max-width: 280px;
      margin-right: 20px;
    }
  }
}

.terms {
  font-size: 0.875rem;
  line-height: 1.125rem;
  color: var(--gray-dark);
}

.addToWallet {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;

  @media screen and (max-width: 767px) {
    margin-bottom: 20px;
  }

  &Button {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

@media print {
  @page {
    size: portrait;
  }
  .container {
    padding: 60px;
  }

  .image {
    &Container {
      width: calc(50% - 20px);
      flex-shrink: 0;
      margin-right: 40px;
    }
  }

  .expires {
    display: inline-flex;
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 0.95rem;
    color: var(--black);
  }

  .info {
    &Header {
      margin-bottom: 10px;
    }
  }

  .mainInfoContainer {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    margin-bottom: 15px;
  }

  .title {
    font-weight: 700;
    color: var(--black-dark);
    margin-bottom: 5px;
    font-size: 1.5rem;
    line-height: 1.5rem;

    &Container {
      flex: 2;
      display: flex;
      flex-direction: column;
      margin-right: 20px;
    }
  }

  .discount {
    &Container {
      display: flex;
      flex-direction: column;
      flex: 1;
    }

    &Value {
      margin-bottom: 5px;
      font-weight: 700;
      font-size: 1.5rem;
      line-height: 1.5rem;
      white-space: nowrap;
      text-transform: capitalize;
      color: var(--primary);
    }

    &Description {
      white-space: nowrap;
      font-size: 1rem;
      color: var(--primary);
      font-weight: 500;
    }
  }

  .subtitle {
    font-weight: 700;
    font-size: 1.5rem;
    line-height: 1.5rem;
    color: var(--black-dark);
  }

  .actions {
    display: none !important;
  }

  .print {
    display: none !important;
  }

  .description {
    margin-bottom: 25px;
    font-size: 1rem;
    line-height: 1.5rem;
    color: var(--black);
  }
}
