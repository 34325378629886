@import "../../../styles/fonts.scss";
@import "../../../styles/_mixins.scss";

.dropdown {
  position: relative;

  &.reversed {
    .list {
      top: inherit;
      bottom: 60px;
      border-radius: 6px 6px 0 0;
    }

    &:first-of-type {
      @media screen and (min-width: 1100px) {
        .dropdownContainer {
          &.isOpened {
            .selectedOption {
              border-radius: 0 0 0 6px;
            }
          }
        }
      }

      @media screen and (max-width: 1099px) {
        .dropdownContainer {
          &.isOpened {
            .selectedOption {
              border-radius: 0 !important;
            }
          }
        }
      }
    }

    &:nth-child(2) {
      @media screen and (max-width: 1099px) {
        .dropdownContainer {
          &.isOpened {
            .selectedOption {
              &.rowView {
                border-radius: 0;
              }
            }
          }
        }
      }
    }
  }

  &Container {
    position: relative;
    width: 100%;

    &.isOpened {
      .selectedOption {
        border-color: var(--primary);

        svg {
          transform: rotate(180deg);
        }
      }
    }

    &.disabled {
      .selectedOption {
        pointer-events: none !important;
        background-color: var(--gray-light);
        color: var(--gray-secondary);
      }
    }
  }

  &:not(:last-of-type) {
    @media screen and (min-width: 1100px) {
      .selectedOption {
        position: relative;
        &:before {
          position: absolute;
          top: -1px;
          right: -1px;
          content: "";
          width: 1px;
          height: calc(100% + 2px);
          background-color: var(--primary);
          opacity: 0;
          transition: var(--transition);
          z-index: 2;
        }
        &:hover {
          &:before {
            opacity: 1;
          }
        }
      }

      .dropdownContainer {
        &.isOpened {
          .selectedOption {
            &:before {
              opacity: 1;
            }
          }
        }
      }
    }

    @media screen and (max-width: 1099px) {
      .selectedOption {
        position: relative;

        &:after {
          position: absolute;
          bottom: -1px;
          left: -1px;
          content: "";
          width: calc(100% + 2px);
          height: 1px;
          background-color: var(--primary);
          opacity: 0;
          transition: var(--transition);
          z-index: 3;
        }

        &:hover {
          &:after {
            opacity: 1;
          }
        }
      }
      .dropdownContainer {
        &.isOpened {
          .selectedOption {
            position: relative;

            &:after {
              opacity: 1;
            }
          }
        }
      }
    }
  }

  &:nth-child(1) {
    @media screen and (min-width: 1100px) {
      .selectedOption {
        border-radius: 6px 0 0 6px;
      }

      .dropdownContainer {
        &.isOpened {
          .selectedOption {
            border-radius: 6px 0 0 0;
          }
        }
      }
    }

    .selectedOption {
      &.rowView {
        @media screen and (max-width: 1099px) {
          border-radius: 6px 0 0 0;
          border-right: none;

          &:before {
            position: absolute;
            top: -1px;
            right: -1px;
            content: "";
            width: 1px;
            height: calc(100% + 2px);
            background-color: var(--primary);
            opacity: 0;
            transition: var(--transition);
            z-index: 2;
          }
        }
      }

      &:not(&.rowView) {
        @media screen and (max-width: 1099px) {
          border-radius: 6px 6px 0 0;
        }
      }
    }

    .dropdownContainer {
      &.isOpened {
        .selectedOption {
          position: relative;
          &.rowView {
            &:before {
              opacity: 1;
            }
          }
        }
      }

      .selectedOption {
        position: relative;
        &.rowView {
          &:hover {
            &:before {
              opacity: 1;
            }
          }
        }
      }
    }
  }

  &:nth-child(2) {
    .selectedOption {
      &.rowView {
        @media screen and (max-width: 1099px) {
          border-radius: 0 6px 0 0;
        }
      }
    }
  }
}

.list {
  position: absolute;
  left: 0;
  top: 60px;
  display: flex;
  flex-direction: column;
  gap: 2px;
  width: 100%;
  min-height: 61px;
  padding: 6px;
  background-color: var(--gray-light-menu);
  border-radius: 0 0 6px 6px;
  overflow-y: auto;
  z-index: 2;

  &Option {
    display: flex;
    align-items: center;
    height: 48px;
    flex-shrink: 0;
    padding: 11.5px 6px;
    background-color: var(--gray-light-menu);
    transition: var(--transition);
    border-radius: 8px;
    cursor: pointer;

    &:hover {
      background-color: var(--white);
    }

    &Text {
      display: flex;
      @include apply-font(preformatted, desktop, regular);
      color: var(--black-light);
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      line-clamp: 1;
      -webkit-box-orient: vertical;
    }

    &Icon {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 24px;
      height: 24px;
      flex-shrink: 0;
      margin-right: 8px;

      svg {
        path {
          fill: var(--primary);
        }
      }
    }
  }
}

.label {
  @include apply-font(body, desktop, caption);
  font-weight: 400;
  color: var(--dark);
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
}

.selectedOption {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 60px;
  padding: 16px 16px 8px 16px;
  background-color: var(--white);
  transition: var(--transition);
  cursor: pointer;

  @media screen and (max-width: 1099px) {
    border-top: 1px solid var(--gray-light-border);
    border-left: 1px solid var(--gray-light-border);
    border-right: 1px solid var(--gray-light-border);
  }

  @media screen and (min-width: 1100px) {
    border: 1px solid var(--gray-light-border);
    border-right: none;
  }

  svg {
    transition: var(--transition);
    color: #636382;

    path {
      transition: var(--transition);
    }
  }

  &:hover {
    border-color: var(--primary);

    svg {
      path {
        stroke: var(--primary);
      }
    }
  }

  &Container {
    display: flex;
    flex-direction: column;
    align-self: flex-start;
    height: 36px;
  }

  &Text {
    display: flex;
    margin-right: 10px;
    @include apply-font(preformatted, desktop, regular);
    color: var(--gray-dark-text);
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    line-clamp: 1;
    -webkit-box-orient: vertical;
  }
}

.arrow {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 28px;
  height: 28px;
  flex-shrink: 0;
  align-self: baseline;
}
