.container {
  position: relative;
  width: 100%;
}

.card {
  max-height: 90vh;
  overflow-y: auto;
}

.closeButton {
  position: absolute;
  top: 8px;
  right: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 25px;
  height: 25px;
  background: rgba(24, 24, 24, 0.3);
  border-radius: 50%;
  border: none;
  outline: none;

  svg {
    width: 12px;
    height: 12px;

    path {
      stroke: var(--white);
    }
  }
}
