.section {
  @media screen and (min-width: 279px) and (max-width: 1279px) {
    padding: 32px 16px 40px;
  }

  @media screen and (min-width: 1280px) {
    padding: 32px 0 60px;

    & + section {
      margin-top: 90px;
    }
  }

  &Container {
    display: grid;
    gap: 20px;
    max-width: 1180px;
    margin: 0 auto;

    @media screen and (min-width: 279px) and (max-width: 1279px) {
      position: relative;
    }

    @media screen and (min-width: 279px) and (max-width: 767px) {
      grid-template-columns: repeat(auto-fill, minmax(250px, 2fr));
    }

    @media screen and (min-width: 768px) {
      grid-template-columns: repeat(3, 1fr);
    }

    @media screen and (min-width: 1920px) {
      margin: 0 auto;
      width: 100%;
    }
  }
}

.card {
  @media screen and (min-width: 279px) and (max-width: 1279px) {
    max-width: inherit !important;
  }
}
