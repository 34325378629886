.section {
  @media screen and (min-width: 279px) and (max-width: 767px) {
    margin-bottom: 60px;
  }

  @media screen and (min-width: 279px) and (max-width: 1279px) {
    padding: 0 15px;
  }

  @media screen and (min-width: 1280px) {
    & + section {
      margin-top: 90px;
    }
  }

  @media screen and (min-width: 1280px) and (max-width: 1919px) {
    padding: 0 105px;
  }

  &Container {
    display: grid;

    @media screen and (min-width: 279px) and (max-width: 1279px) {
      position: relative;
      padding-bottom: 45px;
    }

    @media screen and (min-width: 279px) and (max-width: 767px) {
      grid-template-columns: repeat(auto-fill, minmax(220px, 2fr));
    }

    @media screen and (min-width: 768px) and (max-width: 1279px) {
      grid-template-columns: repeat(3, 1fr);
    }

    @media screen and (min-width: 1280px) {
      grid-template-columns: repeat(3, 1fr);
      margin-bottom: 35px;
      border-left: 1px solid var(--gray-text);
    }
  }

  &Button {
    @media screen and (min-width: 768px) and (max-width: 1279px) {
      width: calc(100% / 3 - 50px / 3);
      margin: 0 auto;
    }

    @media screen and (min-width: 1280px) {
      width: 310px;
      margin: 0 auto;
    }
  }
}

.title {
  font-weight: bold;
  color: var(--black);

  @media screen and (min-width: 279px) and (max-width: 1279px) {
    font-size: 1.5rem;
    line-height: 1.75rem;
    margin-bottom: 30px;
  }

  @media screen and (min-width: 1280px) {
    margin-bottom: 40px;
    text-align: center;
    font-size: 1.625rem;
    line-height: 1.875rem;
  }
}

.card {
  max-width: inherit !important;

  @media screen and (min-width: 1280px) {
    border-top: none !important;
    border-left: none !important;

    &:nth-child(1),
    &:nth-child(2),
    &:nth-child(3) {
      border-top: 1px solid var(--gray-text) !important;
    }
  }
}
