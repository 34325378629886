.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: var(--gray-light);
  border-radius: 10px;
  overflow: hidden;

  &:not(&.popup) {
    max-width: 380px;
  }

  &.popup {
    .subtitle,
    .description,
    .terms {
      -webkit-line-clamp: inherit;
    }

    .header {
      margin-bottom: 20px;
    }

    .discount {
      &Value {
        margin-bottom: 5px;

        @media screen and (min-width: 768px) {
          font-size: 1.5rem;
        }
      }

      &Description {
        @media screen and (min-width: 768px) {
          font-size: 1rem;
        }
      }
    }

    .content {
      @media screen and (max-width: 767px) {
        padding: 15px 15px 30px;
      }

      @media screen and (min-width: 768px) {
        padding: 10px 40px 30px;
      }
    }

    .border {
      @media screen and (max-width: 767px) {
        margin: 50px 0 25px;
      }

      @media screen and (min-width: 768px) {
        margin: 30px 0 35px;
      }

      @media screen and (min-width: 768px) {
        &:before {
          left: -50px;
        }

        &:after {
          right: -50px;
        }
      }

      @media screen and (max-width: 768px) {
        &:before {
          left: -25px;
        }

        &:after {
          right: -25px;
        }
      }

      &:before,
      &:after {
        background-color: rgba(24, 24, 24, 0.8);
      }
    }

    .footer {
      justify-content: space-between;
    }

    .image {
      &Container {
        @media screen and (min-width: 768px) {
          max-width: 580px !important;
        }

        @media screen and (min-width: 768px) {
          max-height: 260px !important;
          min-height: 260px !important;
        }
      }
    }

    .title {
      @media screen and (min-width: 768px) {
        font-size: 1.5rem;
      }
    }

    .subtitle {
      margin-bottom: 5px;

      @media screen and (min-width: 768px) {
        font-size: 1.125rem;
      }
    }
  }
}

.content {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 15px 25px 30px;
}

.description {
  font-size: 0.875rem;
  line-height: 1.5;
  overflow: hidden;
  text-overflow: ellipsis;
  flex-shrink: 0;
  display: -webkit-box;
  -webkit-line-clamp: 6;
  line-clamp: 6;
  -webkit-box-orient: vertical;
  color: var(--dark);
}

.terms {
  font-size: 0.75rem;
  line-height: 1.0625rem;
  min-height: 1.0625rem;
  text-align: center;
  color: var(--gray-dark);
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

.viewDetails {
  display: flex;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 40px;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 0.8125rem;
  color: var(--primary);
  background-color: transparent;
  transition: var(--transition);
  border: none;
  outline: none;
  cursor: pointer;

  & > svg {
    transform: rotate(-90deg);
    opacity: 0;

    path {
      transition: var(--transition);
      stroke: var(--primary-dark);
    }
  }

  &:hover {
    color: var(--primary-dark);

    svg {
      opacity: 1;
    }
  }
}

.description + .border {
  margin-top: 25px;
}

.button {
  background-color: transparent;

  & + .button {
    margin-top: 10px;
  }

  &Container {
    margin-bottom: 20px;

    & + .terms {
      margin-top: 10px;
    }
  }
}

.border {
  position: relative;
  display: flex;
  border: 1px dashed var(--gray-text);
  margin: auto 0 30px;
  width: 100%;

  &:before,
  &:after {
    position: absolute;
    top: -10px;
    content: "";
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: var(--white);
  }

  &:before {
    left: -35px;
  }

  &:after {
    right: -35px;
  }
}

.image {
  max-width: 100%;
  width: 100%;
  object-fit: cover;

  &Container {
    display: flex;
    justify-content: center;
    border-radius: 10px 10px 0 0;
    max-height: 185px;
    min-height: 185px;
    height: 100%;
    overflow: hidden;
  }
}

.dealerPhoto {
  width: 50%;
  max-height: 60%;
  object-fit: contain;
  margin: auto 0;
}

.footer {
  display: flex;
  flex-direction: column;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
  gap: 10px;

  &Container {
    overflow: hidden;
  }
}

.title,
.subtitle {
  font-weight: bold;
  text-transform: capitalize;
  color: var(--black);
  display: -webkit-box;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  line-break: anywhere;
}

.title {
  font-size: 1.25rem;
  line-height: 1.25rem;
}

.subtitle {
  font-size: 0.875rem;
  line-height: 1.25rem;
}

.discount {
  &Value {
    font-weight: 500;
    font-size: 1.375rem;
    line-height: 1.25rem;
    text-transform: capitalize;
    color: var(--primary);
  }

  &Description {
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1.25rem;
    color: var(--primary);
  }

  &Container {
    display: flex;
    flex-direction: column;
  }
}

.expiration {
  &Container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 40px;
    flex-shrink: 0;
    border-bottom: 1px solid var(--gray-text);

    & + .imageContainer {
      border-radius: 0;
    }
  }

  &Date {
    font-weight: 500;
    font-size: 1.0625rem;
    line-height: 17px;
    color: var(--black);
  }
}

.addToWallet {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 15px;

  &Button {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
