.container {
  display: flex;
  flex-direction: column;
  max-width: 310px;
  width: 100%;
  border: 1px solid var(--gray-text);
  transition: var(--transition);

  @media screen and (min-width: 279px) and (max-width: 1049px) {
    padding: 35px 20px 30px;
  }

  @media screen and (min-width: 1050px) {
    padding: 35px 40px 45px;
  }

  &:hover {
    background-color: var(--gray-light-menu);
  }
}

.image {
  display: flex;
  align-items: center;
  flex-shrink: 0;
  width: 100%;
  margin-bottom: 20px;

  img {
    width: 35px;
    height: 35px;
  }
}

.content {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.title {
  margin-bottom: 5px;
  font-weight: bold;
  font-size: 1.25rem;
  line-height: 1.4375rem;
  color: var(--black);
}

.text {
  margin-bottom: auto;
  font-size: 0.875rem;
  line-height: 1.0625rem;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  color: var(--black);
}

.readMore {
  display: flex;
  align-items: center;
  margin-top: 10px;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 0.8125rem;
  color: var(--primary);
  transition: var(--transition);

  & > svg {
    transform: rotate(-90deg);
    opacity: 0;

    path {
      transition: var(--transition);
      stroke: var(--primary-dark);
    }
  }

  &:hover {
    color: var(--primary-dark);

    svg {
      opacity: 1;
    }
  }
}

.button {
  background-color: transparent;
  margin-top: 40px;
  margin-bottom: 10px;
}
